import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import cn from 'classnames';
import { useContactGieniUrl } from 'hooks';

import styles from './styles.module.scss';

export const RequestDemoCall = () => {
  const { t } = useTranslation();
  const contactGieniUrl = useContactGieniUrl();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>
          <h2 className={styles.title}>
            {t('Page.Dashboard.RequestDemoCall.Title')}
          </h2>
          <span className={styles.subtitle}>
            {t('Page.Dashboard.RequestDemoCall.Subtitle')}
          </span>
        </div>

        <Button
          size="medium"
          color="primary"
          target="_blank"
          variant="contained"
          href={contactGieniUrl}
          className={cn(styles.cta, 'brilliance')}
        >
          {t('Page.Dashboard.RequestDemoCall.Cta')}
        </Button>
      </div>
    </>
  );
};
